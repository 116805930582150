@font-face {
  font-family: 'Glacial Indifference';
  src: url('../../font/GlacialIndifference-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glacial Indifference';
  src: url('../../font/GlacialIndifference-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
.risk-table-head {
    background-color: #dfdfdf;
  }
  
  .risk-table-cell {
    color: #001b2b;
    font-family: 'Glacial Indifference' !important;
    font-weight: 500!important;
    font-size: 16px!important;
    font-weight: bold !important;
  }
  .AssessmentName{
    color: black;
    font-family:'Glacial Indifference' !important;
    font-weight: 400;
    font-size: 14px;
    max-width: 27rem;
    word-break: break-word;
    white-space: normal;
  }

 .table-row {
    color: black!important;
    font-family: 'Glacial Indifference' !important;
    font-weight: 400;
    font-size: 14px;
    max-width: 27rem;
    word-break: break-word;
    white-space: normal;
  }
  .custom-button {
    background-color: transparent;
    font-family: 'Glacial Indifference' !important;
    color: var(--primary-color); 
    border: 1px solid;
    font-weight: 400;
    border-color: var(--primary-color);
    font-size: 15px;
    padding: 6px 16px;
  }
  /* styles.css */
.custom-textfield {
  margin-left: 28px !important;
  width: 200%!important;
  max-width: 250px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  margin-top: 14px !important;
  margin-bottom: 10px;
}
.date-field{
  margin-right: 150px!important;
  height: 18px!important;
  margin-bottom: 40px!important;
  padding: 0 !important;
}
 /* .custom-container {
  display: flex;
  position: relative;
  flex-wrap: wrap; 
  gap: 20px; 
  justify-content: space-between; 
  align-items: center;
} */
/* .custom-container .custom-textfield {
  flex: 1 1 calc(33.33% - 16px); 
  min-width: 200px; 
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  display: flex;
  justify-content: center;
} */
/* .custom-textfield input { */
  /* font-family: 'Glacial Indifference' !important;
  padding: 10px;
  height: 24px; */
   
/* } */
 
.custom-textfield .MuiInputLabel-root {
  font-family: 'Glacial Indifference' !important;
  color: #777777; 
  font-size: 15px !important;
  margin-left: 0px!important;
  padding: -2px!important;
}

.custom-form-control {
  width: 100%;
  margin-bottom: 30px!important;
  height: 20px!important;
  padding: 8px;
  box-sizing: border-box;
  margin-left: 39px!important;
  margin-right: 20px!important;
}
.custom-form-control .MuiInputLabel-root {
  font-family: 'Glacial Indifference' !important;
  color: #777777; 
  font-size: 14px !important;
  margin-left: 0px!important;
  padding: -2px!important;
}

.custom-input-label {
  font-weight: bold; 
  color: #4CAF50; 
  font-size: 16px;
} 
/* .custom-container .custom-form-control {
  flex: 1 1 calc(33.33% - 16px); 
  min-width: 200px !important; 
  margin-bottom: 0; 
  margin-left: 10px !important;
  box-shadow: none !important;
}
.custom-container .date-field {
  flex: 1 1 calc(33.33% - 16px); 
  min-width: 200px; 
  position: 'relative' !important;

} */
.riskk-custom-label {
  font-size: 12px !important; /* Increased for better visibility */
  color: #333; /* Darker color for better contrast */
  padding: 0 !important; /* Removed invalid negative padding */
}


 .custom-select {
  width: 100%; 
  background-color: #f1f1f1; 
  padding: 10px; 
  border-radius: 5px; 
  font-size: 14px;
  height: 47px!important;
}

/* .custom-select .MuiMenuItem-root {
  font-size: 14px; 
  color: #333; 
}  */
 .create-risk-add-buttons {
  background-color: #001b2b!important;
  color: #fff!important;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  flex: 1 1 auto;
  text-align: center;
  white-space: nowrap; 
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3); 
}  

 .create-risk-add-buttons:hover {
  background-color: #1565C0!important;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); 
}

/* @media (max-width: 768px) {
  .custom-container {
    flex-direction: column; 
  }

  .custom-container .custom-textfield,
  .custom-container .custom-form-control,
  .custom-container .date-field,
  .custom-container .create-add-buttons {
    flex: 1 1 100%; 
  }
} */
  .pagination-container {
    font-family: 'Glacial Indifference' !important;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: static;
    bottom: 20px;
    left: 0;
    padding: 10px 20px;
    z-index: 9999;
  }
  
  .pagination-total {
    color: #000035;
  }
  
  .pagination-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  
  .pagination-button {
    color: #000035;
    padding: 3px 6px;
    border: 1px solid #000035;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
  }
  
  .pagination-button-current {
    color: #fff;
    background-color: #000035;
    font-weight: bold;
    cursor: default;
  }
  
  .pagination-button-disabled {
    cursor: not-allowed;
  }
  
  .pagination-icon-button {
    color: #000035;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .pagination-icon-button-disabled {
    cursor: not-allowed;
  }
  
  .pagination-icon {
    font-size: 14px;
  }