  @font-face {
    font-family: 'Glacial Indifference';
    src: url('../../font/GlacialIndifference-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Glacial Indifference';
    src: url('../../font/GlacialIndifference-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  .MuiTypography-root {
    color: black !important;
  }
  

.risk-reg-main-div {
  width: 100% !important;
}  

.risk-reg-div-1 {
  flex-shrink: 0;
}

.risk-reg-box-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 9px !important;
  margin: 9px !important;
  border-radius: 4px !important;
  gap: 2 !important;
}

button.risk-reg-btn-1 {
  color: #ffffff;
    font-family: 'Glacial Indifference', sans-serif !important;
    background-color: #001b2b;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3); 
}

button.risk-reg-btn-1:hover {
  color: #ffffff;
  background-color: #1565C0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); 
}

button.risk-reg-btn-3 {
  background: #eeeeee;
  color: #808080;
  font-family: 'Glacial Indifference', sans-serif !important;
  margin-left: 10px;
  float: right;
  width: 5rem;
  padding-top: 6px;
  padding-left: 16px;
  padding-bottom: 6px;
  padding-right: 16px;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
              rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
              rgba(0, 0, 0, 0.12) 0px 1px 5px 0px; 
  transition: box-shadow 0.2s ease-in-out;
}

button.risk-reg-btn-3:hover {
  background-color: #eeeeee;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
              rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
              rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
}

button.risk-reg-btn-2 {
    cursor: pointer;
    color: #ffffff;
    background-color: #001b2b;
    font-family: 'Glacial Indifference', sans-serif !important;
    float: right;
    width: 8.2rem;
    margin-left: 10px;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 5px 0px ; 
    transition: box-shadow 0.2s ease-in-out;
}

button.risk-reg-btn-2:hover {
    background-color: #001b2b;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
    rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
    rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
}
.risk-reg-btn-2:disabled {
  background-color: #eeeeee;
  cursor: not-allowed;
  box-shadow: none;
}
.menu-item-font {
  font-family: 'Glacial Indifference', sans-serif !important;
}

.table-cell-1 {
    font-family: 'Glacial Indifference', sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
}

.table-cell-2 {
    font-family: 'Glacial Indifference', sans-serif !important;
    font-size: 14px !important;
}

.risk-reg-box-2 {
    margin-top: 15px !important;
    margin-left: 15px !important;
}

.risk-reg-box-3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 9px !important;
  margin: 9px !important;
  border-radius: 3px !important;
  gap: 50px !important;
}

.risk-reg-box-4 {
  display: flex;
  gap: 18px !important;
}

.risk-reg-box-5 {
  display: flex;
  flex-direction: column;
  gap: 10px !important;
  padding: 18px !important;
}

.risk-reg-box-9 {
  width: 100% !important;
  height: 100% !important;
  margin-top: 16px !important;
  overflow: auto !important;
  flex-grow: 1 !important;
}

.risk-typography-2 {
    font-family: 'Glacial Indifference', sans-serif !important;
    font-size: 14px !important;
    padding-left: 12px;
    vertical-align: middle;
}

.risk-typography-3 {
    padding-left: 35px;
    vertical-align: middle;
    margin-right: 40px;
}

.risk-typography-4 {
  font-size: 18px !important;
  font-family: 'Glacial Indifference' !important;
}

.risk-typography-5 {
  font-size: 20px !important;
  font-family: 'Glacial Indifference' !important;
}

.risk-chip-2 {
    font-family: 'Glacial Indifference' !important; 
}

.risk-reg-box-6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.risk-reg-box-7 {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px !important;
  border: 1px solid lightgray !important;
  padding: 16px !important;
  border-radius: 4px !important;
}

.pagination-container {
    font-family: 'Glacial Indifference' !important;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: static;
    bottom: 20px;
    left: 0;
    padding: 10px 20px;
    z-index: 9999;
  }
  
.pagination-total {
  color: #000035;
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.pagination-button {
  color: #000035;
  padding: 3px 6px;
  border: 1px solid #000035;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
}

.pagination-button-current {
  color: #fff;
  background-color: #000035;
  font-weight: bold;
  cursor: default;
}

.pagination-button-disabled {
  cursor: not-allowed;
}

.pagination-icon-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #000;
}

.pagination-icon-button:disabled {
  color: #ccc; 
  cursor: not-allowed;
}

.pagination-icon {
  font-size: 14px;
}

/* popup desing */
.impact-typography1 {
  text-align: center !important;
  font-weight: bold !important;
  color: black !important;
  font-family: "Glacial Indifference", sans-serif !important;
}
.impact-list {
  display: flex !important;
  flex-direction: column !important;
  gap: 8px !important; 
}
.impact-typography {
  font-weight: bold !important;
  font-family: "Glacial Indifference" !important;
  font-size: 13px !important;
}
.low-impact {
  padding: 16px; 
  display: flex;
  min-width: 40%;
  max-width: 800px;
  align-items: center;
  gap: 16px; 
  background:  #c8e6c9 !important; ;
  border-radius: 7px!important;
  transition: 0.3s!important;
}

.low-impact:hover {
  transform: scale(1.05);
  background-color: #c8e6c9;
  box-shadow: 4px  rgba(0, 0, 0, 0.2); 
}
.low-error-icon {
  color: green !important;
  font-size: 30px !important;
}
.moderate-impact{
  padding: 16px; 
  display: flex;
  min-width: 40%;
  max-width: 800px;
  align-items: center;
  gap: 16px; 
  background:  #ffe0b2 !important; ;
  border-radius: 7px!important;
  transition: 0.3s!important
}
.moderate-impact:hover{
  transform: scale(1.05);
  background-color: #ffe0b2;
  box-shadow: 4px  rgba(0, 0, 0, 0.2); 
}
.warning-icon {
  color: orange !important;
  font-size: 30px !important;
}

.high_impact{
  padding: 16px; 
  display: flex;
  min-width: 40%;
  max-width: 800px;
  align-items: center;
  gap: 16px; 
  background:  #ffcdd2 !important; ;
  border-radius: 7px!important;
  transition: 0.3s!important;
}
.high_impact:hover{
  transform: scale(1.05);
  background-color: #ffcdd2;
  box-shadow: 4px  rgba(0, 0, 0, 0.2);
}
.danger-icon {
  color: red !important;
  font-size: 30px !important;
}
.likewood-box{
  display: flex !important;
  flex-direction: column !important;
  gap: 8px !important;
 }

.unlikely{
  padding: 16px; 
  width: 100%;
  max-width: 550px;
  display: flex;
  align-items: center;
  margin-left: 50px!important;
  justify-content: center;
  gap: 16px!important; 
  background-color: #b6ecb8!important;
  border-radius: 8px!important; 
  transition: 0.3s!important;
  font-family: 'Glacial Indifference' !important;
}

.unlikely:hover{
  transform: scale(1.09);
  background-color: #b6ecb8!important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); 
}
.unlikely-text {
  font-size: 13px!important;
  color: black;
  font-weight: bold!important;
  font-family: "Glacial Indifference"!important;
  text-align: center;
}
.likely{
  padding: 16px; 
  width: 90%;
  max-width: 550px;
  display: flex;
  align-items: center;
  margin-left: 50px!important;
  justify-content: center;
  gap: 16px!important; 
  background-color: #fcd8a3!important;
  border-radius: 8px!important; 
  transition: 0.3s!important;
  font-family: 'Glacial Indifference' !important;
}
.likely:hover{
  transform: scale(1.09);
  background-color:  #fcd8a3!important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); 
}
.likely-text{
  font-size: 14px!important;
  color: black;
  font-weight: bold!important;
  font-family: "Glacial Indifference"!important;
  text-align: center;
}

.Highly-likely{
  padding: 14px; 
  width: 90%;
  max-width: 550px;
  display: flex;
  margin-left: 50px!important;
  align-items: center;
  justify-content: center;
  gap: 16px!important; 
  background-color: #fab0b7!important;
  border-radius: 8px!important; 
  transition: 0.3s!important;
  font-family: 'Glacial Indifference' !important;
}
.Highly-likely:hover{
  transform: scale(1.09);
  background-color:  #fab0b7!important;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); 
}
.Highly-likely-text{
  font-size: 13px!important;
  color: black;
  font-weight: bold!important;
  font-family: "Glacial Indifference"!important;
  text-align: center;
}

.risk_matrix{
  margin-right: 30px!important;
  min-width: 50%!important;
  min-height: 50%!important;
  margin-top: 16px!important; 
  overflow: auto;
  flex-grow: 1;
}
.risk-matrix-table{
  width: 100% !important;
  border-collapse: collapse !important;
  text-align: center !important;
}

td {
  height: 50px;
}
.low{
  background-Color: green;
 
}
.medium {
  background-color: yellow; 
  color: #000;
}

.high {
  background-color: red;
}
.risk-level{
  margin-top: 8px !important;
  font-family: "Glacial Indifference", sans-serif !important;
}
.check-icon{
  font-size: 30px !important;
  color: #66bb6a !important; 
}
.criteria-low{
  background-color: #d3f9d8!important;
  padding: 24px; 
  border-radius: 16px!important; 
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  font-family: "Glacial Indifference"!important;
  text-align: center;
  border: 2px solid #66bb6a!important;
  transition: transform 0.3s, box-shadow 0.3s !important;
}
.criteria-low:hover{
  transform: scale(1.05);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
}
.risk-rating-low{
  font-size: 12px !important;
  font-family: 'Glacial Indifference', sans-serif !important;
  font-weight: bold;
  color: #333; 
  text-align: center;
  margin-top: 8px; 
}
.warning-icon{
  font-size: 30px !important;
  color: #ffeb3b !important; 
}
.criteria-medium{
  background-color: #fff9c4 !important;
  padding: 24px; 
  border-radius: 16px!important; 
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center!important;
  font-family: "Glacial Indifference"!important;
  font-size: 6px;
  border: 2px solid #ffeb3b!important;
  transition: transform 0.3s!important;
}
.criteria-medium:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
}
.risk-rating-medium{
  font-size: 12px !important;
  font-family: 'Glacial Indifference', sans-serif !important;
  font-weight: bold;
  color: #333; 
  text-align: center;
  margin-top: 8px; 
}
.risk-rating1{
  font-family: "Glacial Indifference", sans-serif !important;
  text-align: center !important;
}
.risk-rating{
  margin-top: 32px !important; 
  font-family: "Glacial Indifference"!important;
  text-align: center !important;
}
.criteria-high {
  background-color: #ffebee!important;
  padding: 24px; 
  border-radius: 16px!important; 
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: "Glacial Indifference";
  border: 2px solid #e57373!important;
  transition: transform 0.3s!important;
}

.criteria-high:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
}
.risk-rating-high{
  font-size: 12px !important;
  font-family: 'Glacial Indifference', sans-serif !important;
  font-weight: bold;
  color: #333; 
  text-align: center;
  margin-top: 8px; 
}
.error-icon{
  font-size: 30px !important;
  color: #e57373 !important; 
}

.criteria-modal {
  position: absolute;
  color: #001b2b;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  border-radius: 12px; 
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 30rem !important;
  min-width: 50%;
  max-width: 100%;
  min-height: 45%;
  max-height: 90%;
}
.popup-flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.popup-full-container {
  width: 100%;
  height: 100%;
  margin-top: -2px;
  overflow: auto;
  flex-grow: 1;
}

.risk-modal{
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Glacial Indifference"!important;
}
.info-modal-title {
  margin-left: 190px !important; 
  font-family: "Glacial Indifference", sans-serif !important; 
  font-weight: bold !important;
  display: block; 
}
