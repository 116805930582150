@font-face {
    font-family: 'Glacial Indifference';
    src: url('../../font/GlacialIndifference-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Glacial Indifference';
    src: url('../../font/GlacialIndifference-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }
  
  .req-main-div {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  
  .req-box-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 6px !important;
    margin: 6px !important;
    gap: 18px !important;
  }
  
  .req-form-control {
    font-family: 'Glacial Indifference', sans-serif !important;
  }
  
  .req-menu-item {
    font-family: 'Glacial Indifference', sans-serif !important;
  }
  
  .req-box-2 {
    padding: 25px !important;
    width: 95% !important;
    display: flex;
    flex-direction: column;
  }
  
  .req-box-3 {
    cursor: pointer;
  }
  
  .req-typography-1 {
    color: black;
    font-size: 16px !important;
    font-family: 'Glacial Indifference', sans-serif !important;
  }
  
  .req-typography-2 {
    margin-top: 5px !important;
    font-family: 'Glacial Indifference', sans-serif !important;
    font-size: 14px !important;
    color: #808080;
  }
  
  .req-typography-3 {
    font-family: 'Glacial Indifference', sans-serif !important;
    font-size: 14px !important;
    color: black;
    margin-right: 10px !important;
  }
  
  .req-box-4 {
    cursor: pointer;
    color: #ffffff;
    border-radius: 4px !important;
    padding: 5px !important;
    text-align: center !important;
    width: 60px !important;
  }
  
  .req-typography-4 {
    font-size: 14px !important;
    font-family: 'Glacial indifference', sans-serif !important;
  }
  
  .req-iconbutton-1 {
    position: absolute !important;
    top: 18px !important;
    left: 16px !important;
    color: #333;
  }
  
  .req-iconbutton-1:hover {
     color: #1565C0;
  }
  
  .req-typography-5 {
    font-weight: bold;
    margin-bottom: 20px !important;
    font-family: 'Glacial indifference', sans-serif !important;
    text-align: center !important;
  }
  
  .req-box-5 {
    margin-bottom: 12px !important;
    padding: 10px !important;
    border-radius: 2px !important;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    background-color: #dddddd;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .req-box-5:hover {
    background-color: #cccccc;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
  
  .req-box-6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .req-typography-6 {
    margin-bottom: 2px !important;
    font-size: 1rem !important;
    font-family: 'Glacial indifference', sans-serif !important;
    font-weight: 500 !important;
    color: #333 !important;
    cursor: pointer;
  }
  
  .req-iconbutton-2 {
    color: #333;
    transition: all 0.3s ease !important;
    border-radius: 50%;
    position: relative;
  }
  
  .req-iconbutton-2:hover {
    color: #3f51b5;
    transform: scale(1.2) !important;
  }
  
  .req-iconbutton-2:hover::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 36px;
    height: 36px;
    background-color: rgba(63, 81, 181, 0.1);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease;
  }
  .req-textfield-comment .MuiInputBase-root {
    height: 75px !important;
  }
  .req-typography-7 {
    text-align: center;
    color: #777;
    font-family: 'Glacial indifference', sans-serif !important;
  }
  
  .req-popup-1 {
    position: absolute;
    top: 48%;
    left: 80%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    padding: 1.8rem;
    border-radius: 10px;
  }
  
  .req-typography-8 {
    font-family: 'Glacial indifference', sans-serif !important;
    font-weight: bold !important;
    font-size: 18px !important;
    text-align: center;
    margin-bottom: 8px !important;
  }
  
  .req-typography-9 {
    font-family: 'Glacial indifference', sans-serif !important;
  }
  
  .req-typography-10 {
    font-size: 14px !important;
    white-space: normal !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    font-family: 'Glacial indifference', sans-serif !important;
  }
  
  .req-formcontrol-1 {
    margin-bottom: 20px !important;
    word-wrap: break-word !important;
  }
  
  .req-formcontrol-1 .MuiFormControlLabel-label {
    font-family: 'Glacial indifference', sans-serif !important;
    font-size: 14px; 
  }
  button.req-submit-button-1 {
    cursor: pointer;
    color: #ffffff;
    background-color: #001b2b;
    font-family: 'Glacial Indifference', sans-serif !important;
    float: right;
    width: 5rem;
    margin-left: 10px;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px ; 
    transition: box-shadow 0.2s ease-in-out !important;
  }
  
  button.req-submit-button-1:hover {
    background-color: #001b2b;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
    rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
    rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
  }
  
  button.req-cancel-button-1 {
    background: #eeeeee;
    color: #808080;
    font-family: 'Glacial Indifference', sans-serif !important;
    margin-left: 10px;
    float: right;
    width: 5rem;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 5px 0px; 
    transition: box-shadow 0.2s ease-in-out;
  }
  button.req-cancel-button-1:hover {
    background-color: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
                rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
                rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
  }
  
  button.req-cancel-button-2 {
    background: #eeeeee;
    color: #808080;
    font-family: 'Glacial Indifference', sans-serif !important;
    margin-right: 10px;
    float: right;
    width: 5rem;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 5px 0px; 
    transition: box-shadow 0.2s ease-in-out;
  }
  button.req-cancel-button-2:hover {
    background-color: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
                rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
                rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
  }
  
  .req-typography-11 {
    font-family: 'Glacial Indifference', sans-serif !important;
    font-size: 18px !important;
    font-weight: bold !important;
    text-align: center !important;
    margin-bottom: 10px !important;
  }
  
  .req-font-1 {
    font-family: 'Glacial Indifference', sans-serif !important;
  }
  
  .req-box-7 {
    display: flex;
    justify-content: center;
    align-items: center !important;
    height: 100% !important;
  }
  
  .req-typography-13 {
    font-family: 'Glacial Indifference', sans-serif !important;
    font-weight: 500 !important;
  }
  
  .req-iconbutton-3 {
    margin-right: 10px !important;
    color: #333 !important;
  }
  
  .req-iconbutton-3:hover {
    color: #1565C0 !important;
  }
  
  .req-accordion-container {
    background-color: #dedede !important;
    border-radius: 2px !important;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 12px !important;
  }
  
  .req-accordion-summary {
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    padding: 0px 10px !important;
    font-family: 'Glacial Indifference', sans-serif !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: black !important;
    width: 100%; 
  }
  
  .subcheck-title {
    flex-grow: 1; 
    max-width: 100%;
    transition: max-width 0.3s ease;
    white-space: normal;
    word-break: break-word; 
    overflow-wrap: anywhere;
    font-family: 'Glacial Indifference', sans-serif !important;
  }
  
  .req-div-2 {
    display: flex;
    align-items: center; 
    margin-left: 10px; 
    white-space: nowrap; 
  }
  
  
  .button-container {
    position: absolute;
    right: 50px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
  
  @media screen and (max-width: 600px) {
    .req-accordion-summary {
      flex-direction: column; 
      align-items: flex-start; 
    }
  
    .subcheck-title {
      max-width: 100%; 
      word-break: break-word; 
    }
  
    .req-div-2 {
      margin-left: 0; 
      margin-top: 8px; 
      width: 100%; 
      justify-content: flex-start; 
    }
  
    .button-container {
      position: static; 
      transform: none; 
    }
  }
  
  button.button-enabled {
    cursor: pointer;
    color: #ffffff;
    background-color: #001b2b;
    font-family: 'Glacial Indifference', sans-serif !important;
    margin-left: 10px !important;
    padding-top: 6px !important;
    padding-left: 16px !important;
    padding-bottom: 6px !important;
    padding-right: 16px !important;
    font-size: 14px !important;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 5px 0px ; 
    transition: box-shadow 0.2s ease-in-out;
  }
  
  button.button-enabled:hover {
    background-color: #001b2b;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
      rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
      rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
  }
  
  button.button-disabled {
    background-color: #d6d6d6;
    color: #666;
    cursor: not-allowed;
    margin-left: 10px !important;
    padding-top: 6px !important;
    padding-left: 16px !important;
    padding-bottom: 6px !important;
    padding-right: 16px !important;
    font-size: 14px;
  }
  
  .req-accordion-details {
    background-color: #eeeeee;
    border-radius: 4px !important;
  }
  
  .artifact-list-container {
    padding: 10px !important;
    background-color: #eeeeee;
    border-radius: 8px !important;
    max-height: 500px !important;
    overflow-y: auto !important;
  }
  
  .artifact-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px !important;
  }
  
  .artifact-info {
    display: flex;
    align-items: center;
    gap: 8px !important;
    flex-grow: 1 !important;
  }
  
  .file-icon {
    color: #333;
    font-size: 20px !important;
  }
  
  .artifact-link {
    font-size: 14px !important;
    font-weight: 600;
    color: #1565c0;
    font-family: 'Glacial Indifference', sans-serif !important;
    text-decoration: none;
    display: flex;
    align-items: center !important;
    gap: 4px !important;
    padding: 4px !important;
    border-radius: 4px !important;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .artifact-link:hover {
    text-decoration: underline !important;
  }
  
  .open-icon {
    font-size: 16px !important;
    color: inherit;
  }
  
  .artifact-meta {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 6px !important;
    border-radius: 4px !important;
  }
  
  .artifact-label {
    display: block !important;
    font-size: 12px;
    color: #666;
    margin-bottom: 4px !important;
    font-family: 'Glacial Indifference', sans-serif !important;
  }
  
  .artifact-value {
    font-size: 14px !important;
    color: #001b2b;
    font-weight: 500;
    font-family: 'Glacial Indifference', sans-serif !important;
  }
  
  .artifact-comment {
    grid-column: 1 / -1 !important;
    word-break: break-word !important;
  }
  
  .italic-text {
    font-style: italic;
    margin: 0;
  }
  
  .req-divider {
    border: none;
    border-top: 1px solid #ddd;
    margin: 16px 0 !important;
  }
  
  .no-artifact-text {
    font-family: 'Glacial Indifference', sans-serif !important;
    font-size: 14px;
    color: #666;
    text-align: center;
    padding: 16px !important;
    background-color: white;
    border-radius: 8px !important;
  }
  
  .loading-text {
    font-family: 'Glacial Indifference', sans-serif !important;
    font-size: 14px;
    color: #666;
    text-align: center;
  }
  
  .req-typography-12 {
    margin-bottom: 5px !important;
    margin-left: 20px !important;
    font-size: 12px !important;
    font-family: 'Glacial Indifference', sans-serif !important;
  }