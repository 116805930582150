
@font-face {
  font-family: 'Glacial Indifference';
  src: url('../font/GlacialIndifference-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Glacial Indifference';
  src: url('../font/GlacialIndifference-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}
/* .vendor-form-container {
    display: flex;
    flex-direction: column; 
    gap: -4px!important; 
    /* max-width: 200px;  */
    /* margin: 0 auto;  */
    /* padding: 10px;  */
  /* } */
   
 
  .vendor-custom-textfield {
    margin-left: 25px!important;
    font-family: 'Glacial Indifference'!important;
    font-size: 13px!important;
    margin-top: 16px!important;
    width: 100%; 
    max-width: 300px!important; 
    font-size: 14px; 
    margin-bottom: 10px;
    /* height: 40px !important; */
  }

  .custom-form-control {
    margin-top: 16px!important;
    font-family: 'Glacial Indifference'!important;
    width: 100%; 
    max-width: 300px!important; 
    margin-bottom: 10px; 
    /* margin-right: 200px !important; */
  }
  
  /* Label styling for inputs */
  .custom-form-control {
    width: 100%;
    /* background-color: #f5f5f5; */
    border-radius: 8px;
    /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); */
  }
  
  .custom-input-label {
    font-weight: bold;
    color: #333;
    padding: -4px!important;
    font-size: 16px!important;
    margin-bottom: 200px!important;
  }
  
  .vendor-custom-select {
    /* background-color: #ffffff; */
    border-radius: 8px;
    padding: 10px 14px;
    height: 50px !important;
    color: black;
  }
  
  /* .vendor-custom-select .MuiSelect-icon {
    color: black;
  } */
  
  .error-text {
    color: red;
    font-size: 14px;
  }
  
  /* Custom styling for the Date Picker TextFields */
  .date-field {
    margin-top: 16px!important;
    width: 100%; /* Full width of container */
    max-width: 170px!important; /* Limit the width */
    margin-bottom: 10px; /* Space between date fields */
   
  }
  
  /* Ensuring consistent font size and input height for Date Fields */
   .MuiInputBase-root {

    font-family: 'Glacial Indifference'!important;
    height: 47px; 
  } 
  
  /* Optional: Custom styling for the date input */
   .MuiInputBase-input {
    padding: 5px; 
  }
   
  /* Box styling for margin adjustments */
  .custom-box {
    margin-top: 3px;
    margin-right: 2px;
  }
  
  
.vendor-custom-table-row {
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .vendor-custom-table-row:hover {
    background-color: #f5f5f5; /* Light gray background on hover */
  }
  
  /* Styling for TableCell */
  .vendor-custom-table-cell {
    font-family: 'Glacial Indifference'!important;
    padding: 16px;
    text-align: right; 
  }
  
button.create-add-vendor-buttons{
  font-family: "glacial indifference";
    background-color: #001b2b!important; 
    color: #fff!important; 
    font-size: 14px; 
    margin-top:1px!important;
    /* padding: 8px 16px;  */
    border: none; 
    border-radius: 4px; 
    cursor: pointer; 
    /* text-transform: capitalize;  */
    transition: background-color 0.3s ease; 
  }
  button.create-add-vendor-buttons{
    background-color: #3c56ff; 
  }

  .vendor-custom-stack {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    /* gap: 8px;  */
  }
  
  .custom-link {
    text-transform: capitalize;
    color: inherit; 
    text-decoration: none; 
  }
  
  /* Styling for the TextField when it's in edit mode */
  .vendor-custom-textfield {
    width: 100%;
    max-width: 200px; /* Adjust max-width as per your requirement */
    font-size: 14px;
  }
  
  /* Styling for the default display of vendor_no (not in edit mode) */
  .vendor-custom-vendor-no {
    text-align: left!important;
    text-transform: capitalize;
    color: black; /* Light gray for the static text */
  }
  
  /* Optional: Additional styling for vendor ID text */
  .vendor-id-text {
    color: black;
    font-size: 11px;
    font-weight: 500;
  }
  
  /* Styling for TextField input label */
  .custom-textfield-label {
    font-size: 14px;
    font-family: 'Glacial Indifference', sans-serif;
    font-weight: 400;
  }
  
  .pagination-container {
    font-family: 'Glacial Indifference' !important;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: static;
    bottom: 20px;
    left: 0;
    padding: 10px 20px;
    z-index: 9999;
  }
  
  .pagination-total {
    color: #000035;
  }
  
  .pagination-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  
  .pagination-button {
    color: #000035;
    padding: 3px 6px;
    border: 1px solid #000035;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
  }
  
  .pagination-button-current {
    color: #fff;
    background-color: #000035;
    font-weight: bold;
    cursor: default;
  }
  
  .pagination-button-disabled {
    cursor: not-allowed;
  }
  
  .pagination-icon-button {
    color: #000035;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .pagination-icon-button-disabled {
    cursor: not-allowed;
  }
  
  .pagination-icon {
    font-size: 14px;
  }
  .table-container {
    min-width: 800px;
    
}
  .scroll-div{
    max-height: 500px; 
    overflow-y: auto; 
    overflow-x: hidden; 

  }
   
  .vendor-table-head {
    font-family: 'Glacial Indifference' !important;
    font-weight: bold !important;
    font-size: 14px !important;
    color: #001b2b;
    position: sticky; /* Makes the element sticky */
    top: 0; /* Sticks the element to the top */
    z-index: 1; /* Ensures it stays on top of other elements */
}
  
.vendor-table-cell {
  font-family: 'Glacial Indifference' !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #000000;
  background-color: #dedede!important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center; /* Align content horizontally */
  vertical-align: middle; /* Align content vertically */
}
  
  .sort-label {
    cursor: pointer;
  }
  
  .language-text {
    font-family: 'Glacial Indifference' !important;
  }