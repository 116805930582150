@font-face {
    font-family: 'Glacial Indifference';
    src: url('../../font/GlacialIndifference-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Glacial Indifference';
    src: url('../../font/GlacialIndifference-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }

.main-div {
    background: #eeeeee;
    padding: 10px;
}

.search-box {
    display: flex;
    margin-bottom: 1rem;
}

.textfield-search {
    width: 98%;
    margin-left: 10px !important;
    font-family: 'Glacial Indifference', sans-serif !important;
}

.MuiInputLabel-root {
    font-family: 'Glacial Indifference', sans-serif !important; 
  }

.filter-box {
    display: flex;
    margin-left: .5rem;
}

.formcontrol-1 {
    width: 30%;
}

.formcontrol-2 {
    width: 55%;
    margin-left: .5rem !important;
}

.checkbox-standard {
    margin-right: .5rem;
}

.req-accordion {
    margin: 10px !important;
}

.accord-details-1 {
    background: #f9f9f9;
    padding: 10px !important;
}

.typography-1 {
    font-family: 'Glacial Indifference', sans-serif !important;
}

.typography-2 {
    font-family: 'Glacial Indifference', sans-serif !important;
    margin-top: 20px !important;
}

.check-box-1 {
    margin: 10px 0 !important;
}

.check-box-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

button.action-button {
    color: #ffffff;
    font-family: 'Glacial Indifference', sans-serif !important;
    background-color: #001b2b;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3); 
}

button.action-button:hover {
    color: #ffffff;
    background-color: #1565C0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); 
}

.check-divider {
    margin: 10px 0 !important;
}

.req-typography-2 {
    margin-top: 1rem !important;
}

.popup-box {
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    padding: 1.8rem;
    border-radius: 10px;
}

.artifact-typography {
    font-family: 'Glacial Indifference' !important;
    font-weight: bold !important;
    font-size: 18px !important;
    text-align: center;
    margin-bottom: 8px !important;
}

.popup-box-2 {
    display: flex;
    justify-content: flex-end;
}

button.popup-cancel-button {
    background: #eeeeee;
    color: #808080;
    font-family: 'Glacial Indifference' !important;
    margin-left: 10px;
    float: right;
    width: 5rem;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 5px 0px; 
    transition: box-shadow 0.2s ease-in-out;
}

button.popup-cancel-button:hover {
    background-color: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
                rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
                rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
}

button.popup-submit-button {
    cursor: pointer;
    color: #ffffff;
    background-color: #001b2b;
    font-family: 'Glacial Indifference' !important;
    float: right;
    width: 5rem;
    margin-left: 10px;
    padding-top: 6px;
    padding-left: 16px;
    padding-bottom: 6px;
    padding-right: 16px;
    font-size: 14px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, 
                rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 5px 0px ; 
    transition: box-shadow 0.2s ease-in-out;
}

button.popup-submit-button:hover {
    background-color: #001b2b;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 5px -1px, 
    rgba(0, 0, 0, 0.15) 0px 4px 6px 0px, 
    rgba(0, 0, 0, 0.1) 0px 2px 10px 1px;
}

.subcheck-accordion {
    margin: 10px 0 !important;
}

.subcheck-typography {
    font-family: 'Glacial Indifference' !important;
    font-size: 18px !important;
}

.accord-details-2 {
    background: #f9f9f9;
    padding: 10px;
    max-height: 430px;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
}

.artifact-typography-2 {
    padding: 10px;
}

.artifact-box {
    display: flex;
    flex-direction: column;
    background: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
}

button.open-artifact-button {
    margin-top: 10px;
    background: #eeeeee;
    color: #808080;
    font-family: 'Glacial Indifference' !important;
}

.popup-box-3 {
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    padding: 1.8rem;
    border-radius: 10px;
}

.renew-artifact-typo {
    font-family: 'Glacial Indifference' !important;
    font-weight: bold !important;
    font-size: 18px !important;
    text-align: center;
    margin-bottom: 8px !important;
}

.iframe-box-1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
    padding: 1.8rem;
    overflow: hidden;
}

.iframe-box-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

button.icon-button-1 {
    background: #eeeeee;
    color: #808080;
}

button.icon-button-1 {
    background: #eeeeee !important;
    color: #808080 !important;
}

.iframe-box-3 {
    height: 100% !important;
    width: 100% !important;
}
.reqlist-textfield-comment .MuiInputBase-root {
    height: 75px !important;
  }
.reqlist-typography-12 {
    margin-bottom: 5px !important;
    margin-left: 20px !important;
    font-size: 12px !important;
    font-family: 'Glacial Indifference', sans-serif !important;
}